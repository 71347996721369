/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { BsShare } from 'react-icons/bs';
import { CgNotes } from 'react-icons/cg';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import ShareIcon from '@lb/components/Icons/ShareIcon';
import months from '@lb/components/SharedFiles/months';
import { refineUrlDomain } from '@lb/components/Utility/utils';

/* Styles */
import styles from '@lb/components/Blogs/Post.module.css';

const Post = ({
  post,
  cardStyle,
  height,
  region,
  lang,
  mx,
  my,
  showShareIcon,
}) => {
  // console.log(Cardstyle);
  let icon = {
    article: <CgNotes />,
    video: <AiOutlinePlayCircle />,
  };
  let date = new Date(post?.createdAt);

  return (
    <>
      <Box
        w={{ base: '100%', sm: '341px', md: '378px' }}
        height={height || { base: '355px', md: '390px' }}
        padding={{ base: '22px', md: '24px' }}
        background="rgba(255, 255, 255, 1)"
        boxShadow="0px 0px 13.3216px 3.3304px rgba(0, 0, 0, 0.12)"
        pos="relative"
        className="post"
        mx={mx}
        my={my}
        // zIndex={2}
      >
        <NextLink
          href={refineUrlDomain(
            post?.url || `${post?.blogContentType}/${post?.urlAlias}`,
            region,
            lang
          )}
          className={styles.post}
          passHref
          legacyBehavior
        >
          <Link textDecor="none" _hover={{ textDecor: 'none' }}>
            <Box pos="relative">
              <LazyLoadImageComponent
                src={post?.cardImage?.url || post?.heroImage?.url}
                objectFit={{ base: 'fill', md: 'cover' }}
                width="100%"
                height={{ base: '178.62px', md: '196.17px' }}
                alt={post?.alt || post?.cardImage?.alt || ''}
              />
              <Box
                pos="absolute"
                w={{ base: '40.35px', md: '46px' }}
                h={{ base: '40.35px', md: '46px' }}
                background="#DB3030"
                color="#ffffff"
                bottom="0"
                right={0}
                fontSize="25px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {icon[post?.contentType || post?.blogContentType]}
              </Box>
            </Box>
            {/* Label */}

            <Box className="label">
              {post?.contentType == 'video' ||
              post?.contentType == 'blogContentType' ? (
                <Box></Box>
              ) : post?.contentType == '' ? (
                <Box></Box>
              ) : null}
            </Box>
            <Box
              fontSize={{ base: '20px', md: '26px' }}
              lineHeight={{ base: '28px', md: '36.4px' }}
              fontWeight="350"
              fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
              fontStyle="normal"
              maxH={{ md: '70.36px', base: '65.25px' }}
              overflow="hidden"
              mt={{ base: '27px', md: '29.23px' }}
              textDecor="none"
              color="#121212"
              as="h4"
              className={styles.postMemory}
              textAlign="left"
              _hover={{ textDecor: 'none' }}
              title={post?.title}
              minH={{ base: '56px', md: '76px' }}
            >
              {post?.title}
            </Box>
            {(cardStyle === 'homepage-blog-list' ||
              post?._modelApiKey === 'linga_bhairavi_blog') && (
              <>
                <Box
                  fontSize={{ base: '14px', md: '16px' }}
                  lineHeight={{ base: '22px', md: '23.2px' }}
                  fontWeight="350"
                  fontFamily="'FedraSansStd-book', system-ui, serif"
                  fontStyle="normal"
                  maxH={{ base: '65.25px', md: '70.36px' }}
                  overflow="hidden"
                  // mt={{ base: '27px', md: '30px' }}
                  textDecor="none"
                  color="#8D7452"
                  className={styles.postWMemory}
                  textAlign="left"
                  as="h5"
                  _hover={{ textDecor: 'none' }}
                  title={post?.title}
                  // minH={{ base: '56px', md: '76px' }}
                >
                  {post?.summary}
                </Box>
                <Flex
                  width="100%"
                  h="auto"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  textAlign="left"
                >
                  <Text
                    textDecor="underline"
                    _hover={{ textDecor: 'underline' }}
                    color="buttonText.color2"
                    fontSize={{ base: 'body3', md: 'body2' }}
                    lineHeight={{ base: '21px', md: '26px' }}
                    fontFamily="FedraSansStd-Book"
                    fontWeight="500"
                    mt="20px"
                    fontStyle="normal"
                    fontFeatureSettings="case on"
                    textTransform="uppercase"
                  >
                    Read More
                  </Text>
                </Flex>
              </>
            )}
          </Link>
        </NextLink>
        {cardStyle !== 'homepage-blog-list' &&
          post?._modelApiKey !== 'linga_bhairavi_blog' && (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mt={{ base: '18px', md: '19.81px' }}
            >
              <Text
                color="bodyText.color2"
                fontSize={{ base: '12.63px', md: 'body3' }}
                lineHeight={{ base: '23.46px', md: '26px' }}
                style={{ fontFeatureSettings: 'case on' }}
                fontStyle="normal"
                fontFamily="FedraSansStd-Book"
                fontWeight="400"
              >
                {date.getDate() < 10 && '0'}
                {date?.getDate()}&nbsp;
                {months[date.getMonth()]} {date.getFullYear()}&nbsp;
                {post?.readingTime || post?.duration ? ' | ' : ''}
                {post?.readingTime
                  ?.replace('~', '')
                  ?.replace('~', '')
                  ?.replace('0 seconds', ' < 1 minute')
                  ?.replace('seconds', 'sec')}
                {post?.duration?.split(':')[0]} {post?.duration ? 'min' : ''}
              </Text>
              <Text
                color="buttonText.color1"
                fontSize={{ base: '20px', md: '24px' }}
                fontWeight="bold"
              >
                {/* <BsShare /> */}
                <ShareIcon
                  background="transparent"
                  url={post?.url}
                  // display={{
                  //   base: showShareIcon ? 'block' : 'none',
                  //   md: 'block',
                  // }}
                  icon={<BsShare />}
                />
              </Text>
            </Flex>
          )}
      </Box>
    </>
  );
};

export default Post;
