/* Built In Imports */
import NextLink from 'next/link';
import React from 'react';

/* External Imports */
import { Box, Flex, Link } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@lb/components/Utility/utils';

/* Services */

/**
 *
 * @param {object} topics
 * @returns
 */

const BlogTopics = ({ topics }) => {
  return <>
    <Flex
      w="100%"
      h="auto"
      display="flex"
      flexWrap="wrap"
      gridGap={{ base: '12px', md: '20px' }}
      gridRowGap={{ base: '12px', md: '20px' }}
      gridColumnGap={{ base: '12px', md: '20px' }}
      pt={{ base: '20px', md: '16px' }}
      // px={{ base: "15px", md: "0" }}
    >
      {topics?.map(topic => (
        <Box
          key={nanoid()}
          w="auto"
          mb={{ base: '15px', md: '20px' }}
          h="auto"
        >
          <NextLink href={refineUrlDomain(topic?.url || topic?.link)} passHref legacyBehavior>
            <Link
              color="#DB3030"
              fontFamily="FedraSansStd-Book"
              fontStyle="normal"
              fontWeight="400"
              fontSize="body2"
              lineHeight="body1"
              /* or 18px */
              padding={{ base: '10px 30px', md: '10px 40px' }}
              bg="transparent"
              border="1px solid #DB3030"
              rounded="full"
              variant="outline"
              textDecor="none"
              _hover={{
                textDecor: 'none',
                variant: 'solid',
                bg: '#DB3030',
                fontWeight: 500,
                color: '#fff',
              }}
              _focus={{
                textDecor: 'none',
                fontWeight: 500,
                variant: 'solid',
                bg: '#DB3030',
                color: '#fff',
              }}
            >
              {topic?.tag || topic?.title}
            </Link>
          </NextLink>
        </Box>
      ))}
    </Flex>
  </>;
};

export default BlogTopics;
